



































































import { userLoginApi } from '@/api/user'
import { showError } from '@/utils/common'
import { defineComponent, reactive, ref } from '@vue/composition-api'
import { Form, FormItem } from 'element-ui'
import { useUserStore } from '@/pinia/modules/user'
import Main from '@/components/Main.vue'
import LocaleSelect from '@/components/LocaleSelect.vue'
import { passportLogin, passportLogout } from '@/passport/api'
import router from '@/router'

enum LoginType {
    Account = 'account',
    Passport = 'passport',
}

export default defineComponent({
    components: {
        Main,
        LocaleSelect,
        [Form.name]: Form,
        [FormItem.name]: FormItem
    },
    setup(props, { root }) {
        const userStore = useUserStore()
        const formInfo = reactive({
            username: '',
            password: ''
        })
        const FormRules = {
            username: [{ required: true, message: root.$t('login.placeholder.username'), trigger: 'blur' }],
            password: [{ required: true, message: root.$t('login.placeholder.password'), trigger: 'blur' }]
        }
        const formRef = ref()
        const submitLoad = ref(false)
        const passportError = root.$route.query.error
        const isPassport = root.$route.query.passport === '1'
        const showPassportError = ref(!!passportError)

        router.replace({ query: {} });

        const loginType = ref(LoginType.Account)

        const loginWithAccount = () => {
            formRef.value
                .validate()
                .then(async () => {
                    submitLoad.value = true
                    try {
                        const token = await userLoginApi(formInfo)
                        userStore.setUserInfo({ token })
                        await userStore.updateUserInfo()
                        root.$router.push('/')
                    } catch (error) {
                        showError(error)
                    }
                    submitLoad.value = false
                })
                .catch(() => { })
        }

        const loginWithPassport = () => {
            passportLogin()
        }

        const handleCloseError = () => {
            showPassportError.value = false
            if(isPassport) {
                passportLogout()
            }
        }

        return {
            formInfo,
            FormRules,
            formRef,
            submitLoad,
            loginWithAccount,
            loginWithPassport,
            loginType,
            LoginType,
            showPassportError,
            passportError,
            handleCloseError,
        }
    }
})
